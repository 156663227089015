if (!process.env.NEXT_PUBLIC_SUPABASE_URL) {
	console.log(
		"From constants.jsx: ",
		"Make sure env variables are established!"
	);
}

export const SUPABASE_URL =
	process.env.NODE_ENV === "development"
		? process.env.NEXT_PUBLIC_SUPABASE_URL
		: process.env.NEXT_PUBLIC_PROD_SUPABASE_URL;
export const SUPABASE_ANON_KEY =
	process.env.NODE_ENV === "development"
		? process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
		: process.env.NEXT_PUBLIC_PROD_SUPABASE_ANON_KEY;
