"use client";
import "./globals.css";
import { Inter } from "next/font/google";
import styles from "./page.module.css";
import NavBar from "../components/NavBar/NavBar";
import React, { useEffect, useLayoutEffect, useState } from "react";

import { ReduxProvider } from "@/redux/ReduxProvider/ReduxProvider";
import { useDispatch, useSelector } from "react-redux";
import { supabase } from "@/utils/supabase/supabase";
import { usePathname } from "next/navigation";


// export const metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };
{
	/*
Landing page for app?
Figure protected route impact on layout
Does layout need to be wrapped in protected route?
Is it better to conditionally render login UI/main app based on authentication


*/
}
const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({ children }) {
	const path = usePathname();
	const [root, setRoot] = useState(false)

	useEffect(() => {

		if (path === "/" ) {
			setRoot(true)
		}
	}, [path]);


	return (
		<html lang="en" className={inter.className}>
			<body>
				<div className={root ? styles.landingBackground : styles.landing}>
					<ReduxProvider>{children}</ReduxProvider>
				</div>
			</body>
		</html>
	);
}
